.h-wrapper
{
    position: absolute;
    z-index: 999;
    width: 100%;
}

.h-container
{
    position: relative;
}

.h-container>div
{
    flex: 1;
    padding-top: 1rem;
}

.left-header
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left-menu
{
    flex: 1;
    display: flex;
    gap: 3rem;
}

.logo
{
    flex: 1;
    font-weight: 600;
    font-size: 2rem;
    color: var(--primary);
    cursor: pointer;
}

.h-container {
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--secondary);
}

.right-menu {
    gap: 3rem;
    color: var(--white);
    margin-right: 1rem;
}


@media (max-width: 640px) {
    .left-menu, .right-menu
    {
        display: none;
    }
}