.r-container {
    overflow: hidden;
    position: relative;
}

.r-head {
    margin-bottom: 2rem;
}

.r-head .heading
{
    font-weight: 300;
    font-size: 1.5rem;
}


.r-card {
    padding-left: 1rem;
    padding-bottom: 1rem;
    gap: 0.6rem;
    border-radius: 10px;
    margin: auto;
    transition: all 300ms ease-in;
}

.card-name
{
    color: #292623;
    font-size: 1rem;
}

/* .r-card>img {
    width: 100%;
    height: 550px;
    object-fit: cover;
} */

.swiper-horizontal {
    overflow: visible;
}

.r-buttons {
    z-index: 9999;
    position: absolute;
    gap: 1rem;
    top: -4rem;
    right: 0;
}

.r-buttons button {
    font-size: 1.2rem;
    padding: 0.2rem 0.8rem;
    color: rgb(138, 138, 142);
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


.r-buttons>:nth-child(2) {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
}

@media (max-width: 640px) {
    .heading
    {
        font-size: 1.2rem;
    }
}