.category-image {
    width: 100%;
    height: 550px;
    object-fit: cover;
}

.blog-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.sales-section {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: 100%;
}

@media (max-width: 640px) {
    .category-image {
        height: 400px;
    } 
}