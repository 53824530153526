.f-container
{
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-bottom: 2rem;
    gap: 5rem;
    border-top: 1px solid var(--secondary);
}

.f-content
{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.f-content__link
{
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.f-content__link>a
{
    color: var(--secondary);
}

.f-content__link>a:hover
{
   text-decoration: underline;
   color: #131110;
}

.f-content__link>span
{
   font-size: 1.2rem;
   color: #131110;
   font-weight: 500;
}

.f-search:nth-child(1)
{
    font-size: 1.2rem;
    color: #131110 !important;
    font-weight: 500;
 }
 

.f-search
{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
}

.search-box>input
{
    padding: 0.6rem 1.4rem;
}

@media (max-width: 640px) {
    .f-container
    {
        display: flex;
        flex-direction: column;
    }
}