.s-container {
    margin-top: 2rem;
    overflow: hidden;
    padding-top: 2rem;
}


.s-left,
.s-right {
    background-color: #F5F7F8;
}

.s-right>div {
    flex: 1;
}

.s-left>div {
    flex: 1;
}

.s-content {
    background-color: #F5F7F8;
    gap: 1rem;
}

.s-content:nth-child(2) {
    padding-left: 2rem;
}

@media (max-width: 640px) {

    .s-left,
    .s-right {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .s-image {
        width: 100%;
    }

    .a-card:nth-child(1) {
        font-size: 2rem;
    }

    .s-content:nth-child(2) {
        padding-left: 0;
    }
}