.b-container {
    overflow: hidden;
    position: relative;
}

.b-head {
    margin-bottom: 2rem;
}

.read-more
{

   display: flex;
   gap: .3rem;
   font-size: .8rem;
   cursor: pointer;
}

.read-more:hover
{
   text-decoration: underline;
}

.r-card
{
    gap: 1rem;
}

.swiper-horizontal
{
    overflow: visible;
}

