.hero-wrapper {
    position: relative;
    /* height: 50rem; */
    background: var(--white);
}

.hero-container>div {
    flex: 1;
}

.hero-right {
    width: 100%;
    height: 100%;
}

.hero-right>img {
    width: 100%;
    height: 100%;
}

.hero-left {
    gap: 3rem;
}

.hero-title {
    position: relative;
    z-index: 1;
}

.hero-title>h1 {
    font-weight: 300;
    font-size: 3.8rem;
    line-height: 4rem;
}

.hero-des {
    line-height: 2rem;
}

.hero-button {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hero-icons {
    gap: 2rem;
}

.hero-icons>span {
    cursor: pointer;
}

@media (max-width: 640px) {
    .hero-container {
        flex-direction: column;
    }

    .hero-title>h1 {
        font-weight: 300;
        font-size: 2rem;
        line-height: 2rem;
    }

    .hero-left {
        margin-top: 8rem;
        gap: 2rem;
    }

    .hero-button
    {
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 2rem;
    }
}