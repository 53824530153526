.a-container {
    overflow: hidden;
    position: relative;
}

.a-head {
    margin-bottom: 2rem;
}

.a-head .heading
{
    font-weight: 300;
    font-size: 1.5rem;
}

.heading
{
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 200;
}

.a-cards
{
    flex: 1;
}

.a-card
{
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 2rem;
}

.card-des
{
    margin-right: 2rem;
}
.card-des>:nth-child(3)
{
    font-size: .8rem;
}

.description
{
    font-size: .8rem;
    color: #949391;
    margin-bottom: 1rem;
}

.card-icon
{
    align-items: start;
    padding-right: 2rem;
    max-width: max-content;
}

.read-more
{

   display: flex;
   gap: .3rem;
   font-size: .5rem;
   cursor: pointer;
}

.read-more:hover
{
   text-decoration: underline;
}


@media (max-width: 640px) {
    .a-cards
    {
        display: flex;
        flex-direction: column;
    }

    .a-card:nth-child(1)
    {
        font-size: 2rem;
    }
}